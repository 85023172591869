import av1 from '../assets/images/avatars/av1.jpg';
import av2 from '../assets/images/avatars/av2.jpg';
import av3 from '../assets/images/avatars/av3.jpg';
import av4 from '../assets/images/avatars/av4.jpg';
import av5 from '../assets/images/avatars/av5.jpg';
import av6 from '../assets/images/avatars/av6.jpg';
import anon from '../assets/images/avatars/anon.png';

const avatars = {
    av1 ,
    av2 ,
    av3 ,
    av4 ,
    av5 ,
    av6 ,
    anon
}

export default avatars;